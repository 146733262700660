import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import logo from "../../images/Logo.svg";

const status = {
  editing: "Editing",
  to_be_signed: "To Be Signed",
  signed: "Signed",
};

const FullPageListResults = ({ pages, navigate, searchText, ...rest }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Last Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pages
                .filter((page) => {
                  return page.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase());
                })
                .slice(0, limit)
                .map((page) => {
                  let edit_date = new Date(page.edit_date["$date"]);
                  return (
                    <TableRow
                      hover
                      key={page._id}
                      onClick={() => navigate(page)}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Avatar src={logo} sx={{ mr: 2 }}>
                            Logo
                          </Avatar>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Typography color="textPrimary" variant="body1">
                            {page.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{status[page.status]}</TableCell>
                      <TableCell>{`${edit_date.toDateString()}`}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pages.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

FullPageListResults.propTypes = {
  customers: PropTypes.array.isRequired,
};

export default FullPageListResults;
