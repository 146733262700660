import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, Navigate, Link as RouterLink } from "react-router-dom";
import full_logo from "../images/Full Logo.svg";
import { Button, Box, Container, Link } from "@material-ui/core";

export default function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const localLogin = (e) => {
    props.handleLogin(
      e,
      { username: email, email: email, password: password },
      navigate
    );
    setEmail("");
    setPassword("");
  };

  if (props.loggedIn) {
    console.log(props.loggedIn);
    return <Navigate to="/pages/drafts" />;
  }

  return (
    <>
      <a href="http://www.alphaterms.com">
        <img
          src={full_logo}
          style={{ height: "200px", width: "375px", objectFit: "cover" }}
        />
      </a>
      <div
        style={{
          backgroundColor: "white",
          width: "40vw",
          // height: "45vh",
          borderRadius: 20,
          boxShadow: "1px 1px 1px black",
          padding: "2vw",
        }}
      >
        {props.noLogin ? (
          <p>Can't login with that email and password</p>
        ) : (
          <></>
        )}
        <Form
          onSubmit={(e) => {
            localLogin(e);
          }}
        >
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="formBasicPassword"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
            />
          </Form.Group>
          <Link component={RouterLink} to="/forgotpassword" sx={{ pb: 2 }}>
            Forgot your password
          </Link>
          <Container
            style={{ paddingRight: 0, paddingLeft: 0 }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pr: 0,
              pl: 0,
            }}
          >
            <Button variant="contained" type="submit">
              Submit
            </Button>

            <Box sx={{ flex: 1 }} />
            <Button onClick={() => navigate({ pathname: "/signup" })}>
              Go To Signup
            </Button>
          </Container>
        </Form>
      </div>
    </>
  );
}
