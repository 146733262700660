import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Button, Box, Container } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import full_logo from "../images/Full Logo.svg";

const Signup = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();

  return (
    <>
      <a href="http://www.alphaterms.com">
        <img
          src={full_logo}
          style={{ height: "200px", width: "375px", objectFit: "cover" }}
        />
      </a>
      <div
        style={{
          backgroundColor: "white",
          // width: "50vw",
          // height: "50vh",
          borderRadius: 20,
          boxShadow: "1px 1px 1px black",
          padding: "2vw",
        }}
      >
        {props.cantSignup ? (
          <p>A user with that email already exists.</p>
        ) : (
          <></>
        )}
        <Form
          onSubmit={(e) =>
            props.handleSignup(
              e,
              {
                first_name: firstName,
                last_name: lastName,
                username: email,
                email: email,
                password: password,
              },
              history
            )
          }
        >
          <Row className="mb-3">
            <Form.Group as={Col} controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
          <Container
            style={{ paddingRight: 0, paddingLeft: 0 }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pr: 0,
              pl: 0,
            }}
          >
            <Button variant="contained" type="submit">
              Sign Up
            </Button>

            <Box sx={{ flex: 1 }} />
            <Button onClick={() => history({ pathname: "/login" })}>
              Go To Login
            </Button>
          </Container>
        </Form>
      </div>
    </>
  );
};

export default Signup;
