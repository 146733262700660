import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import PageList from "../components/customer/PageList";
import PageListToolbar from "../components/customer/PageListToolbar";

const FullPageList = (props) => {
  const [pageList, setPageList] = useState([]);
  const history = useNavigate();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    loadContracts();
  }, [props.status]);

  const navigate = (page) => {
    history({ pathname: `/editor/${page._id["$oid"]}` });
    // for (const page in pageList) {
    //   if (pageList[page].name == e.target.innerText) {
    //     history.push(`/editor/${pageList[page].name}`);
    //   }
    // }
  };

  const loadContracts = () => {
    const authToken = `Token ${localStorage.getItem("token")}`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        status: props.status,
      }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/get_file/`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        data.sort((first, second) => {
          if (first.edit_date["$date"] > second.edit_date["$date"]) {
            return -1;
          } else if (first.edit_date["$date"] < second.edit_date["$date"]) {
            return 1;
          } else {
            return 0;
          }
        });
        console.log(data);
        setPageList(data);
      })
      .catch((err) => console.log(err));
  };

  //takes name at least
  const addNew = (name, option) => {
    const authToken = `Token ${localStorage.getItem("token")}`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: authToken },
      body: JSON.stringify({ name: name, template: option }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/create_file/`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          loadContracts();
        }
      })
      .catch((err) => console.log(err));
  };

  const signOut = (e) => {
    localStorage.removeItem("token");
    history({ pathname: "/" });
  };

  return (
    <>
      <Helmet>
        <title>Pages</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <PageListToolbar
            addNew={addNew}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <Box sx={{ pt: 3 }}>
            <PageList
              pages={pageList}
              navigate={navigate}
              searchText={searchText}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FullPageList;
