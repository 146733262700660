import "./App.css";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import MyEditor from "./components/MyEditor";
import Login from "./components/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useRoutes,
} from "react-router-dom";
import Signup from "./components/Signup";
import { ThemeProvider, StyledEngineProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Context } from "./context/Context";

import DashboardLayout from "./components/DashboardLayout";
import FullPageList from "./pages/FullPageList";
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword";

function App() {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [username, setUsername] = useState("");
  const [noLogin, setNoLogin] = useState(false);
  const [id, setId] = useState(null);
  const [cantSignup, setCantSignup] = useState(false);
  const [routes, setRoutes] = useState([
    {
      path: "pages",
      element: (
        <DashboardLayout loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
      ),
      children: [
        {
          path: "drafts",
          element: (
            <FullPageList username={username} userId={id} status="editing" />
          ),
        },
        {
          path: "to_sign",
          element: (
            <FullPageList
              username={username}
              userId={id}
              status="to_be_signed"
            />
          ),
        },
        {
          path: "signed",
          element: (
            <FullPageList username={username} userId={id} status="signed" />
          ),
        },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ]);
  const content = useRoutes(routes);

  //If the user has already logged in, get their informatioon
  useEffect(() => {
    if (loggedIn) {
      fetch(`${process.env.REACT_APP_API_URL}/api/current_user/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          setId(json.id);
          setUsername(json.username);
        });
    }
  }, []);

  //login user, store information, then redirect them to the to the pages page
  //takes history because when history was declared in App.js, it was undefined when this was called
  const handleLogin = (e, data, history) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/api/users/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if ("non_field_errors" in json) {
          if (
            json["non_field_errors"].includes(
              "Unable to log in with provided credentials."
            )
          ) {
            setNoLogin(true);
          }
        } else {
          if ("token" in json) {
            localStorage.setItem("token", json.token);
            setLoggedIn(true);
            setUsername(json.username);
            // history({ pathname: "/pages/drafts" });
          }
        }
      });
  };

  //signs user up and then redirects them to the login page
  //takes history because when history was declared in App.js, it was undefined when this was called
  const handleSignup = (e, data, history) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/api/create_user/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json.username[0] === "A user with that username already exists.") {
          setCantSignup(true);
        } else {
          //go to login, not straight to pages because the signup call in the backend doesn't return a token
          setLoggedIn(false);
          setCantSignup(false);
          history({ pathname: "/login" });
        }
      });
  };

  return (
    <>
      <div className="App">
        <div
        // style={{
        //   backgroundColor: "#FFFFFF",
        //   minHeight: "100vh",
        //   fontFamily: "Open Sans",
        // }}
        >
          {/* <Router> */}
          <div>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Routes>
                  {/* <Route exact path="/" element={<StaticPage />} /> */}
                  <Route
                    exact
                    path="/"
                    element={<Navigate to="/pages/drafts" />}
                  />
                  <PrivateRoute
                    path="/editor/:doc"
                    loggedIn={loggedIn}
                    element={<MyEditor username={username} userId={id} />}
                  ></PrivateRoute>
                  {/* <PrivateRoute path="/pages" loggedIn={loggedIn}> */}
                  {/* <Pages username={username} userId={id} /> */}
                  {/* </PrivateRoute> */}
                  <LoginRoute
                    path="/login"
                    loggedIn={loggedIn}
                    element={
                      <div
                        style={{
                          minHeight: "100vh",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#61A6AB",
                        }}
                      >
                        <Login
                          handleLogin={handleLogin}
                          noLogin={noLogin}
                          loggedIn={loggedIn}
                        />
                      </div>
                    }
                  >
                    {/* <div
                  style={{
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#61A6AB",
                  }}
                >
                  <Login handleLogin={handleLogin} noLogin={noLogin} />
                </div> */}
                  </LoginRoute>
                  <Route
                    path="/signup"
                    element={
                      <div
                        style={{
                          minHeight: "100vh",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#61A6AB",
                        }}
                      >
                        <Signup
                          handleSignup={handleSignup}
                          cantSignup={cantSignup}
                        />
                      </div>
                    }
                  ></Route>
                  <Route
                    path="/resetpassword/:email"
                    element={<ResetPassword />}
                  />
                  <Route path="/forgotpassword" element={<ForgotPassword />} />
                </Routes>
                <Context.Provider value={{ loggedIn, username, id }}>
                  {content}
                </Context.Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </div>
          {/* </Router> */}
        </div>
      </div>
    </>
  );
}

const PrivateRoute = ({ children, loggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          children
        ) : (
          <Navigate to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
};

const LoginRoute = ({ children, loggedIn, element, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          <Navigate
            to={{ pathname: "/pages/drafts", state: { from: location } }}
          />
        ) : (
          children
        )
      }
    />
  );
};

export default App;
