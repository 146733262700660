import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Button } from "@material-ui/core";

const AddNewModal = (props) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [templates, setTemplates] = useState([]);
  const [option, setOption] = useState("None");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //get all the templates to show in options
  useEffect(() => {
    const authToken = `Token ${localStorage.getItem("token")}`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: authToken },
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/get_templates/`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setTemplates(data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAddNew = () => {
    setShow(false);
    setName("");
    //takes name as input
    props.addNew(name, option);
  };

  const handleChange = (e) => {
    console.log(e);
    setOption(e.target.value);
  };

  return (
    <>
      <Button
        style={{ width: "20%" }}
        variant="contained"
        color="primary"
        onClick={handleShow}
      >
        Create New Term Sheet
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Template</Form.Label>
          <Form.Select value={option} onChange={(e) => handleChange(e)}>
            {templates.map((template) => {
              return <option name={template.name}>{template.title}</option>;
            })}
          </Form.Select>

          <Form.Label>File Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Contract Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddNew}>
            Make Contract
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewModal;
