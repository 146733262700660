import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import logo from "../images/Logo.svg";

const DashboardNavbar = ({ onMobileNavOpen, setLoggedIn, ...rest }) => {
  const history = useNavigate();

  const signOut = () => {
    setLoggedIn(false);
    localStorage.removeItem("token");
    // history({ pathname: "/login" });
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <img src={logo} style={{ height: "52px" }} />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <Button
            sx={{ color: "white" }}
            onClick={() => {
              signOut();
            }}
          >
            Logout
          </Button>
          <IconButton color="inherit" size="large">
            <InputIcon
              onClick={() => {
                signOut();
              }}
            />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
