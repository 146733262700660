import { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Box,
  Container,
  AppBar,
  Toolbar,
  Button,
  Stack,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import logo from "../images/Logo.svg";
const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 256,
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { email } = useParams();

  const handleReset = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/resetpassword/`, requestOptions)
      .then((res) => navigate("/login", { replace: true }))
      .catch((err) => console.log(err));
  };

  return (
    <DashboardLayoutRoot>
      <Helmet>
        <title>Reset</title>
      </Helmet>
      <AppBar elevation={0}>
        <Toolbar>
          <RouterLink to="/">
            <img src={logo} style={{ height: "52px" }} />
          </RouterLink>
        </Toolbar>
      </AppBar>
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Box
              sx={{
                backgroundColor: "background.default",
                minHeight: "100%",
                py: 3,
              }}
            >
              <Container>
                <Stack spacing={2} maxWidth={0.5}>
                  <Typography variant="subtitle1">
                    Enter your new password
                  </Typography>
                  <TextField
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button variant="contained" onClick={handleReset}>
                    Reset Password
                  </Button>
                </Stack>
              </Container>
            </Box>
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default ResetPassword;
