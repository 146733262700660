import React from "react";
// import {
//   Container,
//   Col,
//   Row,
//   Button,
//   DropdownButton,
//   Dropdown,
// } from "react-bootstrap";
import { useSlate } from "slate-react";
import logo from "../images/Logo.svg";
import AddSignature from "./AddSignature";
import ShareModal from "./ShareModal";
import { AppBar, Toolbar, Button, Box } from "@material-ui/core";

const templateText = [
  {
    key: "doc_title",
    name: "Document Title",
    node: { type: "doctitle", children: [{ text: "Title" }] },
  },
  {
    key: "doc_preamble",
    name: "Preamble",
    node: {
      type: "preamble",
      children: [
        { text: "This ___ was made effective as of ___ by and between " },
      ],
    },
  },
  {
    key: "doc_party",
    name: "Party",
    node: {
      type: "party",
      name: "Party A",
      text: "few",
    },
  },
  {
    key: "doc_recitals",
    name: "Recitals",
    node: { type: "recitals", children: [{ text: "" }] },
  },
  {
    key: "doc_agreement",
    name: "Agreement",
    node: { type: "agreement", children: [{ text: "" }] },
  },
  {
    key: "doc_section",
    name: "Section",
    node: { type: "section", children: [{ text: "" }] },
  },
  {
    key: "doc_subsection",
    name: "Subsection",
    node: { type: "sub-section", children: [{ text: "" }] },
  },
];

const Editorbar = (props) => {
  const editor = useSlate();

  return (
    <AppBar elevation={0} position="relative">
      <Toolbar sx={{ height: 64 }}>
        <img
          style={{ height: "52px" }}
          onClick={props.navigate}
          className="navButton"
          src={logo}
        />

        {props.document.status === "editing" ? (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <AddSignature addSignature={props.addSignature} editor={editor} />
          </>
        ) : (
          <></>
        )}

        {props.document.status === "signed" ? (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={props.handlePrint}
              variant="contained"
              color="primary"
            >
              Print
            </Button>
          </>
        ) : (
          <></>
        )}
        {/* {props.document.status === "editing" ? (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <ShareModal
              email={props.shareEmail}
              setEmail={props.setShareEmail}
              sharePage={props.sharePage}
            />
          </>
        ) : (
          <></>
        )} */}
        {props.document.status === "editing" ? (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => props.finalize()}
              variant="contained"
              color="primary"
            >
              Finish and Start Signing
            </Button>
          </>
        ) : (
          <></>
        )}
        {props.document.status === "to_be_signed" ? (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => props.completeSigning()}
              variant="contained"
              color="primary"
            >
              Complete Signing
            </Button>
          </>
        ) : (
          <></>
        )}

        {props.document.status === "editing" ? (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={props.savePage}
              className="navButton"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </>
        ) : (
          <></>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Editorbar;
