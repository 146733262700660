import { useState, useEffect, useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { Context } from "../context/Context";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 256,
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const DashboardLayout = (props) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  // const [loggedIn, setLoggedIn] = useState(
  //   localStorage.getItem("token") ? true : false
  // );
  const [username, setUsername] = useState("");
  const [id, setId] = useState(null);
  const context = useContext(Context);

  //If the user has already logged in, get their informatioon
  // useEffect(() => {
  //   if (loggedIn) {
  //     fetch("http://localhost:8000/api/current_user/", {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Token ${localStorage.getItem("token")}`,
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then((json) => {
  //         console.log(json);
  //         setId(json.id);
  //         setUsername(json.username);
  //       });
  //   }
  // }, []);

  if (!context.loggedIn) {
    console.log(props.loggedIn);
    return <Navigate to="/login" />;
  }

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        loggedIn={context.loggedIn}
        setLoggedIn={props.setLoggedIn}
      />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        setLoggedIn={props.setLoggedIn}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
