import { Transforms, Editor, Text } from "slate";
import CanvasDraw from "react-canvas-draw";
import { useRef } from "react";
import info from "../images/infoIcon.svg";

export const CodeElement = (props) => {
  return (
    <pre {...props.attributes}>
      <code>{props.children}</code>
    </pre>
  );
};

export const DefaultElement = (props) => {
  return <p {...props.attributes}>{props.children}</p>;
};

export const DocumentTitle = (props) => {
  return (
    <h3 {...props.attributes} className="doc-title">
      {props.children}
    </h3>
  );
};

export const Preamble = (props) => {
  return (
    <p {...props.attributes} className="preamble">
      {props.children}
    </p>
  );
};

export const Party = (props) => {
  return (
    <>
      <span
        style={{ backgroundColor: "rgba(100, 100, 100, 0.5)" }}
        value={props.value}
        onChange={(e) => {
          e.preventDefault();
          props.changeVal(e.target.value);
        }}
      >
        {props.children}
      </span>
    </>
  );
};

export const Recitals = (props) => {
  return (
    <>
      {props.children}
      <input {...props.attributes} className="recitals" disabled></input>
    </>
  );
};

export const Agreement = (props) => {
  return (
    <p {...props.attributes} className="agreement">
      {props.children}
    </p>
  );
};

export const Section = (props) => {
  return (
    <p {...props.attributes} className="section">
      {props.children}
    </p>
  );
};

export const SubSection = (props) => {
  return (
    <p {...props.attributes} className="sub-section">
      {props.children}
    </p>
  );
};

export const PlaceHolder = (props) => {
  return (
    <span {...props.attributes} className="placeholder">
      {props.children}
    </span>
  );
};

export const SignatureElement = (props) => {
  const saveableCanvas = useRef(null);
  const elementDiv = useRef(null);
  // let saveableCanvas = null;
  //clear and undo can be added https://www.npmjs.com/package/react-canvas-draw

  return (
    <div ref={elementDiv}>
      {props.children}
      <CanvasDraw
        className="signature"
        {...props.attributes}
        ref={saveableCanvas}
        lazyRadius={0}
        brushRadius={2}
        brushColor="#000000"
        gridColor="rgba(255, 255, 255, 0)"
        canvasWidth={200}
        canvasHeight={50}
        style={{ backgroundColor: "#efefef" }}
        immediateLoading={true}
        disabled={props.element.user !== props.username || !props.canSign}
        onChange={(newValue) => {
          if (newValue !== props.element.saveData) {
            props.updateSave(
              saveableCanvas.current.getSaveData(),
              props.element.id
            );
          }
        }}
        saveData={props.element.saveData}
      ></CanvasDraw>
    </div>
  );
};

export const FontSize = (props) => {
  return (
    <span
      {...props.attributes}
      style={{ fontSize: 10, verticalAlign: "middle" }}
    >
      {props.children}
    </span>
  );
};

export const Leaf = (props) => {
  return (
    <>
      <span
        {...props.attributes}
        onClick={() => {
          if (props.leaf.definable) {
            props.openInfo(props.children.props.leaf.text);
          }
        }}
        style={{
          fontWeight: props.leaf.bold ? "bold" : "normal",
          fontSize: "14px",
          backgroundColor: props.leaf.definable
            ? "rgba(97, 166, 171, 0.4)"
            : "",
        }}
      >
        {props.children}
      </span>
    </>
  );
};

export const CustomEditor = {
  isBoldMarkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.bold === true,
      universal: true,
    });
    return !!match;
  },
  isCodeBlockActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === "code",
    });
    return !!match;
  },
  toggleBoldMark(editor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true }
    );
  },
  toggleCodeBlock(editor) {
    const isActive = CustomEditor.isCodeBlockActive(editor);
    Transforms.setNodes(
      editor,
      { type: isActive ? null : "code" },
      { match: (n) => Editor.isBlock(editor, n) }
    );
  },
  updateFontSize(editor, size) {
    Transforms.setNodes(
      editor,
      { fontSize: size },
      { match: (n) => Text.isText(n), split: true }
    );
  },
  addSignature(editor, id, user, selection) {
    const text = { text: "" };
    const signature = {
      type: "signature",
      children: [text],
      saveData: "",
      user: user,
      id: id,
    };
    const options = typeof selection === "undefined" ? {} : { at: selection };

    Transforms.insertNodes(editor, signature, options);
  },
};
