import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Button } from "@material-ui/core";

const AddSignature = (props) => {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [selection, setSelection] = useState(undefined);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          handleShow();
          setSelection(props.editor.selection);
        }}
      >
        Add Signature
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Signer's Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              props.addSignature(e, email, selection);
              handleClose();
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddSignature;
