import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { createEditor, Editor, Range, Transforms, Text } from "slate";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import {
  CodeElement,
  DefaultElement,
  Leaf,
  CustomEditor,
  SignatureElement,
  DocumentTitle,
  Preamble,
  Party,
  Recitals,
  Agreement,
  Section,
  SubSection,
  PlaceHolder,
} from "./Elements";
import { Container, Button, DropdownButton, Dropdown } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams, useNavigate } from "react-router-dom";
import "./MyEditor.css";
import logo from "../images/Logo.svg";
import ShareModal from "./ShareModal";
import { History, HistoryEditor, withHistory } from "slate-history";
import Parties from "./Parties";
import { useReactToPrint } from "react-to-print";
import AddSignature from "./AddSignature";
import Editorbar from "./Editorbar";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Divider,
} from "@material-ui/core";

// const fontSizes = [2, 4, 6, 8, 10, 12, 16, 20];

const advice = [
  {
    word: "Pre-money",
    definition:
      "Pre-money valuation refers to the value of the company not including external funding.",
  },
];

const MyEditor = ({ username, userId }) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const { doc } = useParams();
  const history = useNavigate();

  const [value, setValue] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  const [lastState, setLastState] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  const [document, setDocument] = useState({});
  const [id, setId] = useState(0);
  const [noAccess, setNoAccess] = useState(false);
  const [parties, setParties] = useState([]);
  const [numParties, setNumParties] = useState(0);
  const [shareEmail, setShareEmail] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [whichInfo, setWhichInfo] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [canSign, setCanSign] = useState(false);
  const ref = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });
  const [definables, setDefinables] = useState([
    {
      word: "valuation",
      definition: "The amount of money a company and their assests is worth.",
    },
    {
      word: "liquidation preference",
      definition: "Who gets preference if the comapny is liquidated.",
    },
    {
      word: "liquidation preference",
      definition:
        "If the company is liquidated, the investor gets a multiplier of their investment back. For example for a liquidation preference 2x and a $100 investment, the investor would receive $200 back automatically.",
    },
    {
      word: "participating preferred",
      definition:
        "Upon exiting, the investor gets an amount back equal to their initial investment plus their percentage share. For example, if a company got liquidated for $11,000, a participating preferred with a $1000 initial and a 10% stake would get back $2,000 ($1,000 + $10,000 * 10%).",
    },
    {
      word: "preferred stock",
      definition:
        "A type of ownership given to investors with different rights from common stock. Preferred stock generally has additional rights detailed in a term sheet.",
    },
    {
      word: "post-money",
      definition:
        "The value of a company after accounting for the value of any money given by investors.",
    },
    {
      word: "dividend",
      definition:
        "Money paid out to shareholders by the board of directors. Usually followed by a %, indicating what percentage of the share’s value will be received in dividends. For example, a 6% dividend on a $1 share would imply a dividend of $0.06 per share.",
    },
    {
      word: "cumulative",
      definition:
        "In the context of dividends, an obligation for a company to pay dividends on preferred shares before it can pay common shareholders.",
    },
    {
      word: "non-cumulative",
      definition:
        "In the context of dividends, dividends in which the company does not have an obligation to pay dividends on preferred shares before paying them on common shares. ",
    },
    {
      word: "voting rights",
      definition:
        "The rights of preferred shareholders related to what actions require a majority of preferred shareholders to enact.",
    },
    {
      word: "preferred majority",
      definition: "The majority of the preferred shareholders.",
    },
    {
      word: "liquidate",
      definition: "The process by which a company is brought to an end.",
    },
    {
      word: "liquidation",
      definition: "The process by which a company is brought to an end.",
    },
    {
      word: "dissolve",
      definition: "The process by which a company is brought to an end.",
    },
    {
      word: "no shop",
      definition:
        "Agreement in which a company cannot look for offers from other investors for company shares.",
    },
    {
      word: "option pool",
      definition:
        "Block of company equity reserved for other future shareholders, to ensure that new stock does not need to be issued to account for these future shareholders.",
    },
    {
      word: "series",
      definition:
        "Denotes which round of shares is being issued. For example, Series A is the first round after seed, Series B is the second round after seed, etc.",
    },
    {
      word: "board",
      definition:
        "Refers to the members of the company that will vote on company matters. Of note are board structures in which the investor can delegate two board members and the last board member is independent. In such a case (assuming two founders) the founders no longer have control and could lose majority.",
    },
    {
      word: "vesting",
      definition:
        "Benefit plan in which the individual being vested receives shares of a company periodically over a certain time period.",
    },
    {
      word: "stock option",
      definition:
        "Provides the holder of the option the ability to purchase a certain amount of stock if they want.",
    },
    {
      word: "IPO",
      definition:
        "Short for Initial Public Offering. The process of putting a private company’s shares and stock on public exchange, thereby allowing members of the public to purchase stake in the company.",
    },
    { word: "pro rata", definition: "Latin for in proportion." },
    {
      word: "co-sale rights",
      definition:
        "Rights in which if the original shareholders (founders) wish to sell their shares, they must also include the preferred shareholders on a proportional basis.",
    },
    {
      word: "merger",
      definition:
        "The process by which two companies are combined through the purchase of one by the other.",
    },
  ]);

  editor.isVoid = (element) =>
    element.type === "signature" ||
    element.type === "placeholder" ||
    element.type === "recitals"
      ? true
      : false;
  editor.isInline = (element) =>
    // element.type === "party" ||
    element.type === "aftersignature" || element.type === "placeholder"
      ? true
      : false;
  // editor.isInline = (element) => (element.bold === true ? true : false);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ _id: doc }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/get_file/`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.length) {
          setValue(data[0].document);
          setLastState(data[0].document);
          setDocument(data[0]);
          traverseDocument(data[0].document);
          let edit = new Date(data[0].edit_date["$date"]);
          let maxid = -1;
          if (data[0].status !== "editing") {
            setIsReadOnly(true);
          }
          //only looking through top level nodes because signatues are currently all top level,
          //if this were to change, it may need to be done recursively
          for (const i in data[0].document) {
            let node = data[0].document[i];
            if (node.type === "signature") {
              if (node.id > maxid) {
                maxid = node.id;
              }
            }
          }
          setId(maxid + 1);
        } else {
          setNoAccess(true);
        }
      })
      .catch((err) => console.log(err));
  }, [doc]);

  useEffect(() => {
    console.log("hi");
    console.log(document.status);
    console.log(document.signers);
    if (
      document.status === "to_be_signed" &&
      document.signers.includes(username)
    ) {
      setCanSign(true);
    } else {
      setCanSign(false);
    }
  }, [username, document]);

  const sharePage = () => {
    console.log(document);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        contract: JSON.stringify({ ...document, document: value }),
        newEmail: shareEmail,
      }),
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/api/update_file/`,
      requestOptions
    ).catch((err) => console.log(err));
  };

  //could be used in an interval to create autosave
  const savePage = () => {
    if (JSON.stringify(value) === JSON.stringify(lastState)) {
    } else {
      setDocument({ ...document, document: value });
      setLastState(value);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          contract: JSON.stringify({ ...document, document: value }),
        }),
      };
      fetch(
        `${process.env.REACT_APP_API_URL}/api/update_file/`,
        requestOptions
      ).catch((err) => console.log(err));
    }
  };

  const renderElement = useCallback(
    (props) => {
      switch (props.element.type) {
        case "code":
          return <CodeElement {...props} />;
        case "signature":
          return (
            <SignatureElement
              {...props}
              updateSave={updateSave}
              username={username}
              canSign={canSign}
            />
          );
        case "doctitle":
          return <DocumentTitle {...props} />;
        case "preamble":
          return <Preamble {...props} />;
        case "party":
          return (
            <Party
              {...props}
              parties={parties}
              setParty={setParty}
              value={inputValue}
              changeVal={changeValue}
            />
          );
        case "recitals":
          return <Recitals {...props} />;
        case "agreement":
          return <Agreement {...props} />;
        case "section":
          return <Section {...props} />;
        case "sub-section":
          return <SubSection {...props} />;
        case "placeholder":
          return <PlaceHolder {...props} />;
        default:
          return <DefaultElement {...props} />;
      }
    },
    [username, canSign]
  );

  const setParty = (e) => {
    console.log(e);
  };

  const openInfo = (text) => {
    console.log(text);
    for (const i in definables) {
      if (definables[i].word === text.toLowerCase()) {
        setWhichInfo(i);
        setShowInfo(!showInfo);
        return;
      }
    }
  };

  const renderLeaf = useCallback(
    (props) => {
      return (
        <Leaf
          {...props}
          setShowInfo={setShowInfo}
          showInfo={showInfo}
          openInfo={openInfo}
        />
      );
    },
    [showInfo, setShowInfo, openInfo]
  );

  const navigate = () => {
    history(-1);
  };

  const enterText = (e, option) => {
    e.preventDefault();
    Transforms.insertNodes(editor, option.node);
  };

  const addSignature = (e, user, selection) => {
    e.preventDefault();
    const node = {
      type: "paragraph",
      children: [{ text: "" }],
    };
    const options = typeof selection === "undefined" ? {} : { at: selection };
    Transforms.insertNodes(editor, node, options);
    CustomEditor.addSignature(editor, id, user, selection);
    setId(id + 1);
  };

  const updateSave = (saveData, id) => {
    let node = -1;
    for (const i in editor.children) {
      if (editor.children[i].type === "signature") {
        if (id === editor.children[i].id) {
          node = i;
          break;
        }
      }
    }
    if (node < 0) {
      console.log(node, id, value, editor.children);
    } else {
      Transforms.setNodes(editor, { saveData: saveData }, { at: [node] });
    }
  };

  const checkForward = (e) => {
    //above returns an array containing the node the cursor is on[0], and the index of the node[1]
    const node = Editor.above(editor)[0];
    const text = node.children[0].text;
    //returns the start point of a selection, if no selection, return cursor position
    const location = Range.start(editor.selection);
    //index of the letter that was just placed
    const startAt = location.offset;

    const end = text.indexOf(">", startAt);
    if (text.substring(startAt, end) === "signature") {
      Transforms.delete(editor, { at: location, distance: end - startAt + 1 });
      addSignature(e);
    }
  };

  const checkBackward = (e) => {
    //above returns an array containing the node the cursor is on[0], and the index of the node[1]
    const node = Editor.above(editor)[0];
    const text = node.children[0].text;
    //returns the start point of a selection, if no selection, return cursor position
    const location = Range.start(editor.selection);
    //index of the letter that was just placed
    const startAt = location.offset;
    const end = text.indexOf("<");
    if (end < startAt) {
      if (text.substring(end + 1, end + 11) === "signature:") {
        const email = text.substring(end + 11, startAt);
        console.log(email);
        const newLocation = { offset: end, path: location.path };
        Transforms.delete(editor, { at: newLocation, distance: startAt - end });
        addSignature(e, email);
      }
    }
    console.log();
  };

  const traverseDocument = (node) => {
    Transforms.setNodes(
      editor,
      { definable: false },
      { match: (n) => Text.isText(n) }
    );
    for (let i = 0; i < node.length; i++) {
      if (node[i].hasOwnProperty("children")) {
        traverseDocument(node[i].children);
      }
      if (node[i].hasOwnProperty("text")) {
        let positions = [];
        for (const j in definables) {
          let nextPos = node[i].text.toLowerCase().search(definables[j].word);
          let position = nextPos;
          while (nextPos >= 0) {
            let selectRange = {
              anchor: {
                path: ReactEditor.findPath(editor, node[i]),
                offset: position,
              },
              focus: {
                path: ReactEditor.findPath(editor, node[i]),
                offset: position + definables[j].word.length,
              },
            };
            positions.push(selectRange);
            nextPos = node[i].text
              .substr(position + definables[j].word.length)
              .toLowerCase()
              .search(definables[j].word);
            position += nextPos + definables[j].word.length;
          }
        }
        positions.sort((first, second) => {
          if (first.anchor.offset > second.anchor.offset) {
            return -1;
          } else if (first.anchor.offset < second.anchor.offset) {
            return 1;
          } else {
            return 0;
          }
        });
        for (const i in positions) {
          Transforms.setNodes(
            editor,
            { definable: true },
            { match: (n) => Text.isText(n), at: positions[i], split: true }
          );
        }
      }
    }
  };

  const changeValue = (newVal) => {
    setInputValue(newVal);
  };

  //recursively traverses a node and its children and returns true if it or its children is the type, and false otherwise
  const isType = (node, type) => {
    let isNodeType = false;
    for (const i in node) {
      if (node[i].hasOwnProperty("children")) {
        let tmp = isType(node[i].children, type);
        isNodeType = isNodeType || tmp;
      }
      if (node[i].hasOwnProperty("type")) {
        if (node[i].type === type) {
          return true;
        }
      }
    }
    return isNodeType;
  };

  const finalize = () => {
    let signers = [];
    for (const i in editor.children) {
      if (editor.children[i].type === "signature") {
        if (!signers.includes(editor.children[i].user)) {
          signers.push(editor.children[i].user);
        }
      }
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        _id: doc,
        contract: JSON.stringify({
          ...document,
          document: value,
          signers: signers,
          status: "to_be_signed",
        }),
      }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/finalize/`, requestOptions)
      .then((data) => {
        setDocument({
          ...document,
          document: value,
          signers: signers,
          status: "to_be_signed",
        });
        if (data.status === 200) {
          setIsReadOnly(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const completeSigning = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        _id: doc,
        contract: JSON.stringify({
          ...document,
          document: value,
        }),
      }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/sign/`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setValue(data[0].document);
        setLastState(data[0].document);
        setDocument(data[0]);
      })
      .catch((err) => console.log(err));
  };

  // const addInfo = () => {
  //   // console.log(Editor.above(editor));
  //   // if (typeof Editor.above(editor) !== "undefined") {
  //   //   let node = Editor.above(editor);
  //   //   for (let i = node[0].children.length - 1; i >= 0; i++) {
  //   //     Transforms.unsetNodes(editor, "definable", {
  //   //       match: (n) => Text.isText(n),
  //   //       at: [0, i],
  //   //       split: false,
  //   //     });
  //   //   }
  //   // Transforms.unsetNodes(editor, "definable", {
  //   //   match: (n) => Text.isText(n),
  //   //   at: node[1],
  //   //   split: true,
  //   // });
  //   // }
  //   if (typeof editor.selection !== "") {
  //     console.log("hi");
  //     console.log(Editor.leaf(editor, editor.selection.focus.path));
  //     console.log("there");
  //   }
  // };

  return (
    <>
      {noAccess ? (
        <Container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Row id="navigation">
            <Col md={1} style={{ padding: "0px" }}>
              <Button
                style={{ width: "100%" }}
                onClick={navigate}
                className="navButton"
              >
                Back
              </Button>
            </Col>
          </Row>
          <Row>You don't have access to this document</Row>
        </Container>
      ) : (
        <div sytle={{ overflowY: "hidden" }}>
          {/* <Parties
            parties={parties}
            setParties={setParties}
            numParties={numParties}
            setNumParties={setNumParties}
          /> */}
          {showInfo ? (
            <Card
              style={{
                height: "200px",
                width: "20%",
                // backgroundColor: "rgba(0, 0, 0, 0.2)",
                position: "absolute",
                top: "120px",
                left: "20px",
                // border: "3px solid #61A6AB",
              }}
            >
              <CardContent>
                <Typography variant="h4" component="div">
                  {definables[whichInfo].word}
                </Typography>
                <Divider />
                <br />
                <Typography variant="body2">
                  {definables[whichInfo].definition}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <></>
          )}
          <Slate
            editor={editor}
            value={value}
            onChange={(newValue) => {
              console.log(newValue);
              setValue(newValue);
              // if (newValue !== value) {
              //   addInfo();
              // }
            }}
          >
            <Editorbar
              navigate={navigate}
              enterText={enterText}
              addSignature={addSignature}
              handlePrint={handlePrint}
              shareEmail={shareEmail}
              setShareEmail={setShareEmail}
              sharePage={sharePage}
              savePage={savePage}
              doc={doc}
              completeSigning={completeSigning}
              finalize={finalize}
              document={document}
            />
            <Container
              style={{
                background: "#fff",
                // boxShadow:
                //   "rgb(0 0 0 / 10%) 1px 1px 0px inset, rgb(0 0 0 /7%) 0px -1px 0px inset",
                // borderWidth: "1 1 1 6",
                width: "6.2in",
              }}
            >
              <Row style={{ boxShadow: "7px 10px 10px 2px #101010" }}>
                <Col className="visible-print" ref={ref}>
                  <Editable
                    id="scrollable"
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    readOnly={isReadOnly}
                    onKeyDown={(e) => {
                      if (e.key === "<") {
                        checkForward(e);
                      } else if (e.key === ">") {
                        checkBackward(e);
                      }

                      if (e.ctrlKey && e.key === "b") {
                        e.preventDefault();
                        CustomEditor.toggleBoldMark(editor);
                      }
                    }}
                  />
                </Col>
              </Row>
            </Container>{" "}
          </Slate>
        </div>
      )}
    </>
  );
};

export default MyEditor;
